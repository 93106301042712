import React from 'react';
import PropTypes from 'prop-types';
import _omitBy from 'lodash/omitBy';
import _chunk from 'lodash/chunk';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import EditableReferenceLabel from '../../../../EditableReferenceLabel';
import { initialStyles, checkedStyles } from './style';

const MAX_AMOUNT = 4;
const MAX_CHUNK = 4;
const MIN_CHUNK = 2;

function AmountButton({
  amount,
  cssStyle,
  currentAmount,
  onClick,
  currency,
  disabled,
  isPreview,
}) {
  const isChecked =
    amount?.toString().replace(',', '') ===
    currentAmount?.toString().replace(',', '');
  const styles = _omitBy(cssStyle, _isEmpty);

  return (
    <Box
      sx={{
        mb: '8px',
        ml: '8px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '1rem',
        flexGrow: 1,
        flexBasis: 0,
        '&:first-of-type': {
          ml: 0,
        },
      }}
      key={_uniqueId()}
    >
      <Box
        sx={{
          ...initialStyles,
          ...styles,
          ...(isChecked && checkedStyles),
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        disabled={disabled}
        onClick={onClick(amount)}
        data-testid="DonationAmountsSelector/button"
      >
        {currency}
        {isPreview ? <EditableReferenceLabel html={amount} /> : amount}
      </Box>
    </Box>
  );
}

function WithAmountContainer(children) {
  return function({
    paymentOptions,
    currentAmount,
    onClick,
    currency,
    withCustom,
    disabled,
    isPreview,
  }) {
    const baseParams = {
      onClick,
      currency,
      currentAmount,
      disabled,
    };
    const chunkSize =
      paymentOptions.length === MAX_AMOUNT && !withCustom
        ? MIN_CHUNK
        : MAX_CHUNK;
    const chunks = _chunk(paymentOptions, chunkSize);

    return chunks.map((chunk) => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          flexDirection: 'row',
          m: 0,
        }}
        key={_uniqueId()}
        data-testid="WithAmountContainer/ButtonsContainer"
      >
        {chunk.map((option) => {
          const {
            attributes: { amountNumber, cssStyle },
          } = option;
          return children({
            cssStyle,
            isPreview,
            amount: amountNumber,
            ...baseParams,
          });
        })}
      </Box>
    ));
  };
}

AmountButton.propTypes = {
  amount: PropTypes.number.isRequired,
  currentAmount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default WithAmountContainer(AmountButton);
