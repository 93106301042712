import React from 'react';
import PropTypes from 'prop-types';
import { Uploader } from '../../../../uploader';
import UploadProgress from '../UploadProgress';
import CtaButton from '../../../shared/CtaButton';

function PreviewStateButtons({
  hidden,
  ctaButtonContent,
  ctaButtonStyle,
  file,
  uploadUrl,
  uploadCreateCallback,
  setFiles,
  setIsPreviewState,
}) {
  if (hidden) {
    return null;
  }

  const progressContainerRef = React.useRef(null);
  const uploadMediaButtonRef = React.useRef(null);
  const cancelPreviewButtonRef = React.useRef(null);
  const progressBarRef = React.useRef(null);
  const progressPercentageRef = React.useRef(null);

  let activeUpload;
  const setActiveUploadRequest = (request) => {
    activeUpload = request;
  };

  const cancelPreview = () => {
    setFiles([]);
    setIsPreviewState(false);
  };

  const cancelUpload = () => {
    if (activeUpload) {
      activeUpload.abort();
    }

    cancelPreview();
  };

  const handleUploadMedia = () => {
    progressContainerRef.current.classList.remove('hidden');
    uploadMediaButtonRef.current.classList.add('hidden');
    cancelPreviewButtonRef.current.classList.add('hidden');
    const upload = new Uploader(
      file,
      uploadUrl,
      progressPercentageRef.current,
      progressBarRef.current,
      setActiveUploadRequest,
    );

    upload.create(uploadCreateCallback);
  };

  return (
    <>
      <UploadProgress
        containerRef={progressContainerRef}
        percentageRef={progressPercentageRef}
        progressBarRef={progressBarRef}
        cancelUpload={cancelUpload}
      />
      <button
        className="button media-background__button media-background__button--redo"
        data-testid="redo-button"
        type="button"
        onClick={cancelPreview}
        ref={cancelPreviewButtonRef}
      />
      <CtaButton
        className="button media-background__button"
        data-testid="confirm-upload-button"
        type="submit"
        onClick={handleUploadMedia}
        buttonRef={uploadMediaButtonRef}
        ctaButtonContent={ctaButtonContent}
        ctaButtonStyle={ctaButtonStyle}
      />
    </>
  );
}

PreviewStateButtons.propTypes = {
  hidden: PropTypes.bool,
  askUuid: PropTypes.string,
  uploadCreateCallback: PropTypes.func.isRequired,
  ctaButtonContent: PropTypes.string.isRequired,
  file: PropTypes.object,
  uploadUrl: PropTypes.string,
  setFiles: PropTypes.func,
  setIsPreviewState: PropTypes.func,
};

PreviewStateButtons.defaultProps = {
  ctaButtonContent: 'Save Changes',
};

export default PreviewStateButtons;
