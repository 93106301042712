import React, { useState } from 'react';
import { Select } from '../../../../inputs/Select';
import PropTypes from 'prop-types';

function MultipleChoiceSelect(props) {
  const [optionId, setOptionId] = useState();
  const handleSelectChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];

    setOptionId(selectedOption.dataset.id);
  };

  return (
    <>
      <Select {...props} onSelect={handleSelectChange} />
      <input type="hidden" defaultValue={optionId} name={props.optionName} />
    </>
  );
}

MultipleChoiceSelect.propTypes = {
  optionName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  cssStyle: PropTypes.object.isRequired,
};

export default MultipleChoiceSelect;
