import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, BrandingName, Logo } from './style';

function BrandingLink({ name, schemalessUrl, logoUrl }) {
  return (
    <Anchor
      data-testid="CustomBranding/Anchor"
      target="_blank"
      href={schemalessUrl}
    >
      {logoUrl && (
        <Logo
          className="branding__logo"
          data-testid="CustomBranding/Logo"
          logoUrl={logoUrl}
        />
      )}
      <BrandingName
        className="branding__name"
        data-testid="CustomBranding/BrandingName"
      >
        {name}
      </BrandingName>
    </Anchor>
  );
}

BrandingLink.propTypes = {
  name: PropTypes.string.isRequired,
  schemalessUrl: PropTypes.string,
  logoUrl: PropTypes.string,
};

export default BrandingLink;
