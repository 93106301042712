import React from 'react';
import PropTypes from 'prop-types';
import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';
import _isNaN from 'lodash/isNaN';
import _isNull from 'lodash/isNull';
import _uniqueId from 'lodash/uniqueId';
import styled from 'styled-components';
import { commaFormatter, kFormatter } from '../../../utils';
import EditableReferenceLabel from '../../EditableReferenceLabel';

import { ProgressBar } from './components';

const Container = styled.div`
  height: fit-content;
  background-color: white;
  padding: 0.875rem 1rem;
  padding-top: 0.625rem;
`;

// Put AskMetric inside this component when you need round corners for container
const AskMetricWrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
`;

const CurrentAmount = styled.div`
  display: inline;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: black;
`;

const Caption = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: grey;
  max-width: 758px;
  margin: 0 auto;
  margin-top: 0.32rem;
`;

const Description = styled.div`
  line-height: 1.2;
  margin-left: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MetricWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const SocialMetricName = styled.div`
  line-height: 1.5rem;
  color: #727278;
  margin-right: 0.25rem;
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SocialMetricValue = styled.span`
  line-height: 1.5rem;
  margin-left: 0.25rem;
  color: black;
`;

const toInteger = (v) => (_isNumber(v) ? v : 0);
const toString = (v) =>
  !_isNaN(v) && (_isString(v) || _isNumber(v)) ? v.toString() : '';

const prepareOtherProgressValues = ({
  otherGoalAmount,
  otherGoal,
  otherGoalName,
}) => {
  const otherAmountMatch = toString(otherGoalAmount).match(/\d+/);
  const otherGoalMatch = toString(otherGoal).match(/\d+/);
  let current, currentAmount, full, restGoal, restName, splitted;

  if (otherAmountMatch) {
    splitted = otherGoalAmount.split(otherAmountMatch);
    current = toInteger(Number(otherAmountMatch[0]));
    currentAmount = splitted.join(
      toString(commaFormatter(Number(otherAmountMatch[0]))),
    );
  }

  if (otherGoalMatch) {
    splitted = otherGoal.split(otherGoalMatch[0]);
    full = toInteger(Number(otherGoalMatch[0]));
    restGoal = splitted.join(toString(commaFormatter(otherGoalMatch[0])));
  }

  current = current || toInteger(otherGoalAmount);
  currentAmount = currentAmount || toString(otherGoalAmount);
  full = full || toInteger(otherGoal);
  restGoal = restGoal || toString(otherGoal);

  return {
    restLabel: ['of', restGoal, toString(otherGoalName)].join(' '),
    current,
    currentAmount,
    full,
  };
};

const prepareProgressValues = ({
  goal,
  raiseAmountByBlock,
  raiseAmountByExperience,
  raiseAmountByAsker,
  raiseAmountByVariant,
  raiseGoalType,
  raiseGoal,
  raiseGoalName,
  raiseCurrency,
  ...rest
}) => {
  const key = goal.split('_')[0];
  const getRaiseAmount = () => {
    if (raiseGoalType === 'by_block') {
      return raiseAmountByBlock;
    } else if (raiseGoalType === 'by_experience') {
      return raiseAmountByExperience;
    } else if (raiseGoalType === 'by_variant') {
      return raiseAmountByVariant;
    } else {
      return raiseAmountByAsker;
    }
  };
  const raiseAmount = getRaiseAmount();

  if (goal === 'raise_amount') {
    return {
      current: toInteger(raiseAmount),
      full: toInteger(raiseGoal),
      currentAmount: `${raiseCurrency}${toString(
        commaFormatter(toInteger(raiseAmount)),
      )}`,
      restLabel: [
        'of',
        `${raiseCurrency}${toString(commaFormatter(raiseGoal))}`,
        toString(raiseGoalName),
      ].join(' '),
    };
  } else if (goal === 'other_amount') {
    return prepareOtherProgressValues({
      otherGoalAmount: rest.otherGoalAmount,
      otherGoal: rest.otherGoal,
      otherGoalName: rest.otherGoalName,
    });
  } else {
    return {
      current: toInteger(rest[`${key}Amount`]),
      currentAmount: toString(commaFormatter(rest[`${key}Amount`])),
      full: toInteger(rest[`${key}Goal`]),
      restLabel: [
        'of',
        toString(commaFormatter(rest[`${key}Goal`])),
        toString(rest[`${key}GoalName`]),
      ].join(' '),
    };
  }
};

function renderGoalProgress(props) {
  const { currentAmount, restLabel, full, current } = prepareProgressValues(
    props,
  );
  const { isPreview } = props;

  return (
    <>
      <Caption style={{ flexWrap: 'nowrap' }}>
        <CurrentAmount>{currentAmount}</CurrentAmount>{' '}
        <Description>
          {isPreview ? (
            <EditableReferenceLabel
              style={{
                borderRadius: '4px',
                borderWidth: '1px',
                padding: '0 1px',
              }}
              html={restLabel}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: restLabel}} />
          )}
        </Description>
      </Caption>
      <ProgressBar full={full} current={current} />
    </>
  );
}

function renderSocialMetrics({
  amountRaisedMetric,
  amountRaisedMetricName,
  amountRaisedByExperienceMetric,
  amountRaisedByExperienceMetricName,
  amountRaisedByAskerMetric,
  amountRaisedByAskerMetricName,
  amountRaisedByVariantMetric,
  amountRaisedByVariantMetricName,
  submissionsMetric,
  submissionsMetricName,
  raiseCurrency,
  raiseAmountByBlock,
  raiseAmountByExperience,
  raiseAmountByAsker,
  raiseAmountByVariant,
  submissionAmount,
  otherMetric,
  otherMetricName,
  otherMetricAmount,
  isPreview,
}) {
  const metricStrings = [];
  const prepareCurrentAmount = (v) =>
    _isNaN(v) || _isNull(v) ? '' : kFormatter(v);
  const prepareCurrentOtherAmount = (value) => {
    if (_isNaN(value) || _isNull(value)) {
      return '';
    }
    const match = value.match(/\d+/);

    if (match) {
      return value.split(match[0]).join(kFormatter(Number(match[0])));
    } else {
      return value;
    }
  };

  if (amountRaisedMetric) {
    const currentAmount = [
      raiseCurrency,
      prepareCurrentAmount(raiseAmountByBlock),
    ].join('');

    metricStrings.push({
      metricValue: currentAmount,
      metricName: ' ' + amountRaisedMetricName,
    });
  }

  if (amountRaisedByExperienceMetric) {
    const currentAmount = [
      raiseCurrency,
      prepareCurrentAmount(raiseAmountByExperience),
    ].join('');

    metricStrings.push({
      metricValue: currentAmount,
      metricName: ' ' + amountRaisedByExperienceMetricName,
    });
  }

  if (amountRaisedByAskerMetric) {
    const currentAmount = [
      raiseCurrency,
      prepareCurrentAmount(raiseAmountByAsker),
    ].join('');

    metricStrings.push({
      metricValue: currentAmount,
      metricName: ' ' + amountRaisedByAskerMetricName,
    });
  }

  if (amountRaisedByVariantMetric) {
    const currentAmount = [
      raiseCurrency,
      prepareCurrentAmount(raiseAmountByVariant),
    ].join('');

    metricStrings.push({
      metricValue: currentAmount,
      metricName: ' ' + amountRaisedByVariantMetricName,
    });
  }

  if (submissionsMetric) {
    metricStrings.push({
      metricValue: prepareCurrentAmount(submissionAmount),
      metricName: ' ' + submissionsMetricName,
    });
  }

  if (otherMetric) {
    metricStrings.push({
      metricValue: prepareCurrentOtherAmount(otherMetricAmount),
      metricName: ' ' + otherMetricName,
    });
  }

  return (
    <Caption>
      {metricStrings.map(({ metricValue, metricName }) => (
        <MetricWrapper key={_uniqueId()}>
          <SocialMetricValue>{metricValue}</SocialMetricValue>
          <SocialMetricName>
            {isPreview ? (
              <EditableReferenceLabel
                style={{
                  borderRadius: '4px',
                  borderWidth: '1px',
                  padding: '0 1px',
                }}
                html={metricName}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: metricName}} />
            )}
          </SocialMetricName>
        </MetricWrapper>
      ))}
    </Caption>
  );
}

function AskMetric(props) {
  const {
    goal,
    submissionsMetric,
    amountRaisedMetric,
    amountRaisedByExperienceMetric,
    amountRaisedByAskerMetric,
    amountRaisedByVariantMetric,
    otherMetric,
  } = props;
  const socialMetrics =
    submissionsMetric ||
    amountRaisedMetric ||
    amountRaisedByExperienceMetric ||
    amountRaisedByAskerMetric ||
    amountRaisedByVariantMetric ||
    otherMetric;
  const withGoalProgress = goal && goal !== 'none';

  return (
    <Container data-testid="AskMetric/Container">
      {withGoalProgress && renderGoalProgress(props)}

      {socialMetrics && renderSocialMetrics(props)}
    </Container>
  );
}

const propTypes = {
  goal: PropTypes.string.isRequired,
  submissionAmount: PropTypes.number,
  submissionGoal: PropTypes.number,
  submissionGoalName: PropTypes.string,
  otherGoalAmount: PropTypes.string,
  otherGoal: PropTypes.string,
  otherGoalName: PropTypes.string,
  raiseAmountByBlock: PropTypes.number,
  raiseAmountByExperience: PropTypes.number,
  raiseAmountByAsker: PropTypes.number,
  raiseAmountByVariant: PropTypes.number,
  raiseGoalType: PropTypes.string,
  raiseGoal: PropTypes.number,
  raiseGoalName: PropTypes.string,
  raiseCurrency: PropTypes.string,
  submissionsMetric: PropTypes.bool,
  submissionsMetricName: PropTypes.string,
  amountRaisedMetric: PropTypes.bool,
  amountRaisedMetricName: PropTypes.string,
  amountRaisedByExperienceMetric: PropTypes.bool,
  amountRaisedByExperienceMetricName: PropTypes.string,
  amountRaisedByAskerMetric: PropTypes.bool,
  amountRaisedByAskerMetricName: PropTypes.string,
  amountRaisedByVariantMetric: PropTypes.bool,
  amountRaisedByVariantMetricName: PropTypes.string,
  otherMetric: PropTypes.bool,
  otherMetricAmount: PropTypes.string,
  otherMetricName: PropTypes.string,
};

AskMetric.propTypes = propTypes;

const defaultProps = {
  goal: 'none',
  submissionAmount: NaN,
  submissionGoal: NaN,
  otherGoalAmount: '',
  otherGoal: '',
  raiseAmountByBlock: 0,
  raiseAmountByExperience: 0,
  raiseAmountByAsker: 0,
  raiseAmountByVariant: 0,
  raiseGoal: NaN,
  raiseCurrency: '$',
  submissionsMetric: false,
  submissionsMetricName: 'submissions',
  amountRaisedMetric: false,
  amountRaisedMetricName: 'donations',
  amountRaisedByExperienceMetric: false,
  amountRaisedByExperienceMetricName: 'experience raised',
  amountRaisedByAskerMetric: false,
  amountRaisedByAskerMetricName: 'asker raised',
  amountRaisedByVariantMetric: false,
  amountRaisedByVariantMetricName: 'asker raised',
  otherMetric: false,
  otherMetricAmount: '0',
};

AskMetric.defaultProps = defaultProps;

export default AskMetric;
export { propTypes, defaultProps, AskMetricWrapper };
