import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: absolute;
  top: 0.25rem;
  width: calc(100% - 0.5rem);
  left: 0.25rem;
  z-index: 3;

  @media (min-width: 961px) {
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 100%;
    background-color: ${(props) =>
      props.addTransparency ? 'rgba(0, 0, 0, 0.4)' : '#000'};
  }
`;

const Step = styled.span`
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.transparent ? 'rgba(255, 255, 255, 0.4)' : '#fff'};
  width: 100%;
  height: 0.25rem;
`;

const StepsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, minmax(auto, 235px));
  justify-content: center;
  grid-gap: 2px;
  border-radius: 50%;

  @media (min-width: 961px) {
    max-width: 758px;
  }
`;

function ProgressBar({ stepsCount, currentStep, blockWithMedia }) {
  if (parseInt(stepsCount) < 2) {
    return null;
  }

  const renderSteps = () => {
    const steps = [];
    for (let step = 1; step <= stepsCount; step++) {
      if (step <= currentStep) {
        steps.push(<Step key={step} />);
      } else {
        steps.push(<Step key={step} transparent />);
      }
    }

    return steps;
  };

  return (
    <Container addTransparency={!blockWithMedia}>
      <StepsContainer count={stepsCount}>{renderSteps()}</StepsContainer>
    </Container>
  );
}

ProgressBar.propTypes = {
  stepsCount: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  blockWithMedia: PropTypes.bool.isRequired,
};

export default ProgressBar;
